var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{class:'modal-allowance-type',attrs:{"title":_vm.isEditing ? 'Edit Time Off Category' : 'Create New Time Off Category',"show":_vm.show,"submitLabel":_vm.isEditing ? 'Submit' : 'Add New',"loadingSubmit":_vm.loadingSubmit,"isCancel":false,"isSubmit":true},on:{"hide":_vm.hide,"onSubmit":_vm.onSubmit},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14-px-normal form-timeoff-type",attrs:{"multipart":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"Category Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Category Name","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Category Name"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"Paid Days","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Paid Days","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Paid Days","type":"number","min":"1","onkeyup":"if(this.value<0){this.value= this.value * -1}"},model:{value:(_vm.formData.paid_days),callback:function ($$v) {_vm.$set(_vm.formData, "paid_days", $$v)},expression:"formData.paid_days"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"Time Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"radio-btn-timeoff",attrs:{"label":"Time Type","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-radio',{attrs:{"native-value":"yearly"},model:{value:(_vm.formData.time_type),callback:function ($$v) {_vm.$set(_vm.formData, "time_type", $$v)},expression:"formData.time_type"}},[_vm._v(" Yearly ")]),_c('b-radio',{attrs:{"native-value":"monthly"},model:{value:(_vm.formData.time_type),callback:function ($$v) {_vm.$set(_vm.formData, "time_type", $$v)},expression:"formData.time_type"}},[_vm._v(" Monthly ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Is Paid Leave?"}},[_c('b-switch',{attrs:{"true-value":"1","false-value":"0"},model:{value:(_vm.formData.is_paid),callback:function ($$v) {_vm.$set(_vm.formData, "is_paid", $$v)},expression:"formData.is_paid"}},[_vm._v(" "+_vm._s(_vm.formData.is_paid == 1 ? 'Yes' : 'No')+" ")])],1)],1)])])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }