<template>
  <Modal
    :title="
      isEditing ? 'Edit Time Off Category' : 'Create New Time Off Category'
    "
    :show="show"
    :class="'modal-allowance-type'"
    :submitLabel="isEditing ? 'Submit' : 'Add New'"
    :loadingSubmit="loadingSubmit"
    :isCancel="false"
    :isSubmit="true"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submit)"
          class="is-label-14-px-normal form-timeoff-type"
          multipart
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="Category Name"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Category Name"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.name"
                    placeholder="Category Name"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="Paid Days"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Paid Days"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.paid_days"
                    placeholder="Paid Days"
                    type="number"
                    min="1"
                    onkeyup="if(this.value<0){this.value= this.value * -1}"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="Time Type"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field
                  label="Time Type"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                  class="radio-btn-timeoff"
                >
                  <b-radio v-model="formData.time_type" native-value="yearly">
                    Yearly
                  </b-radio>
                  <b-radio v-model="formData.time_type" native-value="monthly">
                    Monthly
                  </b-radio>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <b-field label="Is Paid Leave?">
                <b-switch
                  v-model="formData.is_paid"
                  true-value="1"
                  false-value="0"
                >
                  {{ formData.is_paid == 1 ? 'Yes' : 'No' }}
                </b-switch>
              </b-field>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../components/Modals/Modal.vue'

export default {
  components: { Modal },

  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
