<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Time Off Category">
      <template #page-header>
        <h2 class="page-title">Request Management</h2>
        <r-menu-tab :menu-data="menuHeaders" />
        <!-- <r-menu-tab :level="3" /> -->
      </template>
      <template #action-bar>
        <div class="level">
          <div class="level-left">
            <b-button
              tag="button"
              class="is-command"
              icon-left="plus"
              @click.native="openModalCreate()"
            >
              New
            </b-button>
          </div>
        </div>
      </template>

      <template #page-content>
        <div id="table-request-employment" class="table-timeoff-type">
          <b-table
            :data="timeOffTypesList"
            :per-page="perPage"
            :loading="isTimeoffTypeListLoading"
            @page-change="onPageChange"
            :show-detail-icon="showDetailIcon"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column label="No" v-slot="props" width="5%">
                <span class="is-capitalize">
                  {{ props.index + 1 }}
                </span>
              </b-table-column>

              <b-table-column
                field="name"
                label="Category Name"
                v-slot="props"
                sortable
                width="57%"
              >
                <span class="is-capitalize">
                  {{ props.row.name }}
                </span>
              </b-table-column>
              <b-table-column
                field="is_paid"
                label="Paid Leave?"
                v-slot="props"
                sortable
                width="10%"
              >
                <span class="is-capitalize">
                  {{ props.row.isPaid === 0 ? 'Unpaid' : 'Paid' }}
                </span>
              </b-table-column>
              <b-table-column
                field="paid_days"
                label="Paid Days"
                v-slot="props"
                sortable
                width="10%"
              >
                <span class="is-capitalize">
                  {{ props.row.paidDays }}
                </span>
              </b-table-column>

              <b-table-column
                field="time_type"
                label="Time Type"
                v-slot="props"
                sortable
                width="10%"
              >
                <span class="is-capitalize">
                  {{ props.row.timeType }}
                </span>
              </b-table-column>

              <b-table-column label="Action" v-slot="props" width="8%">
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="openModalCreate(props.row)"
                ></b-icon>
                <b-icon
                  type="is-primary"
                  icon="delete"
                  custom-size="mdi-18px"
                  class="timeoff-type-delete-icon"
                  @click.native="deletionWarning(props.row.id)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <TimeOffCategoryModal
          :show="isModalOpen"
          :formData="data"
          :loadingSubmit="isSubmitting"
          :isEditing="isEditing"
          @hide="closeModalCreate"
          @submit="submit"
        />
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast, generateMessageList } from '@/services/util'
import TimeOffCategoryModal from './TimeOffCategoryModal.vue'

export default {
  data() {
    return {
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      menuHeaders: null,
      defaultSortOrder: 'desc',
      sortField: 'name',
      sortOrder: 'desc',
      search: '',
      showDetailIcon: true,
      isTimeoffTypeListLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      data: {
        id: '',
        name: null,
        paid_days: null,
        time_type: null,
        createdAt: null,
        updatedAt: null,
        is_paid: 0,
      },
      isSubmitting: null,
    }
  },
  computed: {
    ...mapGetters({
      timeOffTypesList: 'timeOffModule/getAllTimeOffTypesList',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadAllTimeOffTypes: 'timeOffModule/getAllTimeOffTypes',
      saveTimeoffType: 'timeOffModule/saveTimeoffType',
      editTimeoffType: 'timeOffModule/editTimeoffType',
      deleteTimeoffType: 'timeOffModule/deleteTimeoffType',
    }),
    ...mapMutations({
      setAllTimeOffTypes: 'timeOffModule/setAllTimeOffTypes',
    }),

    /**
     * Failsafe for level 3 headers
     */
    generateMenuData() {
      this.menuHeaders = [
        {
          title: 'Time Off Category',
          path: '/settings/request-management/time-off-category',
        },
        {
          title: 'Reimbursement Setting',
          path: '/settings/request-management/reimbursement-category',
        },
      ]
    },

    /**
     * Load more table data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadTimeoffTypesList()
        this.isLoadMore = false
      }
    },

    /**
     * reset table state to default
     */
    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setAllTimeOffTypes([])
      await this.loadTimeoffTypesList()
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadTimeoffTypesList()
    },

    /**
     * Load Time off type data
     */
    async loadTimeoffTypesList() {
      this.isTimeoffTypeListLoading = true
      try {
        const response = await this.actionLoadAllTimeOffTypes({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        })
        this.total = response.data.total
        this.lastPage = response.data.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isTimeoffTypeListLoading = false
    },

    /**
     * Open create modal. If row present,
     * set form to include data from selected table row
     * @param {integer} row - table row index (optional)
     */
    openModalCreate(row) {
      if (row) {
        this.isEditing = true
        this.data = {
          id: row.id,
          name: row.name,
          paid_days: row.paidDays,
          time_type: row.timeType,
          is_paid: row.isPaid,
        }
      }
      this.isModalOpen = true
    },

    /**
     * Close Create Modal
     */
    closeModalCreate() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    /**
     * Submit reimbursement category form to database
     */
    async submit() {
      this.isSubmitting = true
      let form = new FormData()
      try {
        if (!this.isEditing) {
          form.append('name', this.data.name)
          form.append('time_type', this.data.time_type)
          form.append('paid_days', this.data.paid_days)
          form.append('is_paid', this.data.is_paid)
          await this.saveTimeoffType(form)
          showToast('Data is successfully added!', 'is-success', 'is-top')
        } else {
          await this.editTimeoffType(this.data)
          showToast('Data is successfully edited!', 'is-success', 'is-top')
          this.isEditing = false
        }
        this.isModalOpen = false
        this.resetForm()
      } catch (e) {
        const errorList = generateMessageList(e.response.data.message)
        showToast(errorList, 'is-danger', 'is-top')
      }
      this.isSubmitting = false
    },

    /**
     * reset form when modal is closed
     */
    resetForm() {
      this.data = {
        id: '',
        name: null,
        paid_days: null,
        time_type: null,
        is_paid: 0,
      }
    },

    /**
     * Open warning sweet alert when deleting a data
     * @param {integer} id - selected ID from database
     */
    deletionWarning(id) {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        denyButtonText: 'No',
        inputValue: 1,
        customClass: {
          confirmButton: 'button is-primary',
          denyButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: 'Are you sure you want to delete this data?',
      }).then(async (res) => {
        try {
          if (res && res.isConfirmed) {
            await this.deleteTimeoffType(id)
            this.$swal({
              icon: 'success',
              titleText: 'Data is successfully deleted!',
              confirmButtonText: 'Done',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
          }
        } catch (e) {
          this.$swal({
            icon: 'error',
            titleText: 'Failed. Please try again!',
            confirmButtonText: 'Go Back Home',
            customClass: {
              confirmButton: 'button is-grey',
            },
          })
        }
      })
    },
  },
  async mounted() {
    this.setAllTimeOffTypes([])
    await this.loadTimeoffTypesList()
    this.generateMenuData()
  },
  components: { TimeOffCategoryModal },
}
</script>
